import React, { useState, useEffect, Fragment } from "react"
import _ from "lodash"
import { Elements, StripeProvider, injectStripe } from "react-stripe-elements"
import LoadingOverlay from "react-loading-overlay"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import classNames from "classnames"
import AsyncSelect from "react-select/async"
import CardElementWithFormik from "./CardElementWithFormik"
import Coupon from "./Coupon"
import styles from "./PaymentForm.module.css"
import PaymentFormSelector from "./PaymentFormSelector/PaymentFormSelector"
import ListLocality from "./ListLocalities/ListLocality"
import computedPrice from "../../shared/pricecomputation"
import { TotalPriceDisplay } from "./TotalPriceDisplay/TotalPriceDisplay"
import { PaymentModalMessage } from "./PaymentModalMessage"
import { format as formatDate } from "date-fns"

const PaymentFormContainer = ({ suburbOption = {}, onSubmit }) => {
  const [stripe, setStripe] = useState(null)

  useEffect(() => {
    // SSR rendering: https://github.com/stripe/react-stripe-elements#server-side-rendering-ssr
    setStripe(window.Stripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY))
  }, [])

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <PaymentForm
          localityName={suburbOption.l}
          localityId={suburbOption.id}
          propertyType={suburbOption.pt}
          month={suburbOption.mnt}
          hasData={!!suburbOption.ok}
          onSubmit={onSubmit}
        />
      </Elements>
    </StripeProvider>
  )
}

// ============================================================================
// SUB-COMPONENTS
// ============================================================================

const PaymentForm = injectStripe(
  ({
    stripe,
    localityName,
    localityId,
    propertyType,
    month,
    hasData,
    onSubmit,
  }) => {
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [coupon, setCoupon] = useState(null)
    const [listLocalities, setListLocalities] = useState([
      {
        location: localityName,
        id: localityId,
        propertyType: propertyType,
        month: month,
        hasData: hasData,
      },
    ])
    const [showSelector, setShowSelector] = useState(false)
    const [price, setPrice] = useState(
      Number(process.env.GATSBY_REPORT_PRICE_CENTS / 100)
    )
    const [backLocalities, setBackLocalities] = useState(null)
    const [overAllPrice, setOverAllPrice] = useState(
      Number(process.env.GATSBY_REPORT_PRICE_CENTS / 100)
    )
    const [noDataList, setNoDataList] = useState([])
    const [removeSuburbMess, setRemoveSuburbMess] = useState("")
    const [classNameAsync, setClassNameAsync] = useState()

    useEffect(() => {
      const currentPrice = Number(process.env.GATSBY_REPORT_PRICE_CENTS)

      const suburbEachPrice = computedPrice.computedPrice(
        listLocalities,
        currentPrice
      )

      setPrice(suburbEachPrice)
      if (listLocalities.length >= 15) {
        setShowSelector(false)
      }

      const calculateTotalReportPriceInCents = coupon => {
        if (!coupon) return listLocalities.length * price

        return coupon.amount_off
          ? listLocalities.length * price - coupon.amount_off / 100
          : listLocalities.length * price * (1 - coupon.percent_off / 100)
      }

      let calculatedtotalPrice = calculateTotalReportPriceInCents(coupon)
      setOverAllPrice(calculatedtotalPrice)
    }, [listLocalities, coupon, price])

    const setShowSelectorHandler = () => {
      if (validationArray.includes(false)) {
        setShowSelector(false)
        setRemoveSuburbMess(
          "Please remove the suburb with unreliable data on the list first by clicking on it."
        )
      } else {
        setShowSelector(true)
        setClassNameAsync(true)
        setRemoveSuburbMess("")
      }
    }
    // input the data from the user's selected options to the listlocalities variable
    const multipleLocalityHandler = option => {
      setShowSelector(true)

      setClassNameAsync(true)

      if (!!option.ok) {
        setListLocalities([
          ...listLocalities,
          {
            location: option.l,
            id: option.id,
            propertyType: option.pt,
            hasData: !!option.ok,
            month: option.mnt,
          },
        ])

        if (listLocalities.length < 1) {
          setShowSelector(true)
          setListLocalities([
            ...listLocalities,
            {
              location: option.l,
              id: option.id,
              propertyType: option.pt,
              hasData: option.ok,
              month: option.mnt,
            },
          ])
        } else if (listLocalities.length >= 15) {
          setListLocalities(listLocalities)
          setShowSelector(false)
        }
      } else {
        setShowSelector(false)
        setNoDataList([
          ...noDataList,
          {
            location: option.l,
            id: option.id,
            propertyType: option.propertyType,
            hasData: option.ok,
            month: option.mnt,
          },
        ])
      }
      setClassNameAsync(false)
    }

    const removeReportHandler = index => {
      let localitiesDelete = [...listLocalities]
      localitiesDelete.splice(index, 1)
      setListLocalities(localitiesDelete)
      setShowSelector(true)
    }

    const removeHandlerNoData = index => {
      let noDataListDelete = [...noDataList]
      setRemoveSuburbMess("")
      noDataListDelete.splice(index, 1)
      setNoDataList(noDataListDelete)
      setShowSelector(true)
      setClassNameAsync(true)
    }

    let validationArray = noDataList.map(noDataLocality => {
      return !!noDataLocality.hasData
    })

    if (backLocalities && backLocalities.length > 0) {
      return (
        <div className={styles.suburbName}>
          <PaymentModalMessage>
            {backLocalities.map((reportLink, i) => {
              const propertyType =
                reportLink.propertyType === "H" ? "House" : "Unit"
              const date = formatDate(new Date(reportLink.month), "MMMM yyyy")

              return (
                <div key={i} style={{ marginBottom: "1.5rem" }}>
                  <p
                    className={styles.linkDisplay}
                    style={{ marginBottom: "0.25rem" }}
                  >
                    {reportLink.location} - {propertyType} - {date}
                  </p>
                  <p className={styles.linkDisplay}>
                    You can also review your report here -{" "}
                    <a
                      rel="noopener noreferrer"
                      href={`/report/growth-prediction/?id=${reportLink.reportId}`}
                      target="_blank"
                    >
                      View Report
                    </a>
                  </p>
                </div>
              )
            })}
          </PaymentModalMessage>
        </div>
      )
    }

    return (
      <LoadingOverlay active={isLoading} spinner text="Processing...">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            confirmEmail: "",
            coupon: "",
            isStripeComplete: null,
            hasAgreedToTermsConditions: null,
          }}
          validationSchema={PaymentFormSchema}
          onSubmit={async (
            {
              // Don't include isStripeComplete and hasAgreedToTermsConditions
              // in submission
              isStripeComplete,
              hasAgreedToTermsConditions,
              coupon,
              ...values
            },
            { setSubmitting }
          ) => {
            const mappedLocalities = listLocalities.map(locality => {
              const { hasData, ...l } = locality
              return l
            })

            delete values.confirmEmail

            const { token } = await stripe.createToken()
            setLoading(true)
            const res = await fetch(`${process.env.GATSBY_API_URL}reports`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              mode: "cors",
              cache: "default",
              body: JSON.stringify({
                ...values,
                stripeToken: token.id,
                coupon: coupon.toUpperCase(),
                localities: mappedLocalities,
              }),
            })
            const json = await res.json()
            setLoading(false)

            if (json.success) {
              setBackLocalities(json.data.reportsArr)
            } else {
              setError(json.error || "Server error")
            }
            setSubmitting(false)
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue, setFieldError }) => (
            <Form className={styles.checkout} noValidate>
              <p
                className={classNames(styles.purchaseText, "mb-3 text-center")}
              >
                Would you like to complete the purchase?
              </p>

              <div className="mb-3">
                <Field
                  name="firstName"
                  className={styles.input}
                  placeholder="First name"
                  autoFocus
                />
                <ErrorMessage name="firstName" component="div" />
              </div>

              <div className="mb-3">
                <Field
                  name="lastName"
                  className={styles.input}
                  placeholder="Last name"
                />
                <ErrorMessage name="lastName" component="div" />
              </div>

              <div className="mb-3">
                <Field
                  name="email"
                  className={styles.input}
                  type="email"
                  placeholder="Email"
                />
                <ErrorMessage name="email" component="div" />
              </div>
              <div className="mb-3">
                <Field
                  name="confirmEmail"
                  className={styles.input}
                  type="email"
                  placeholder="Confirm Email"
                />
                <ErrorMessage name="confirmEmail" component="div" />
              </div>

              <div className="row mb-3">
                {listLocalities &&
                  listLocalities.map((locality, index) => {
                    return (
                      <ListLocality
                        price={price}
                        coupon={coupon}
                        locality={locality}
                        key={index}
                        noDataList={noDataList}
                        listLocalities={listLocalities}
                        removeClick={() => removeReportHandler(index)}
                      />
                    )
                  })}{" "}
                {noDataList &&
                  noDataList.map((locality, index) => {
                    return (
                      <ListLocality
                        price={price}
                        coupon={coupon}
                        locality={locality}
                        key={index}
                        noDataList={noDataList}
                        listLocalities={listLocalities}
                        removeClick={() => removeHandlerNoData(index)}
                      />
                    )
                  })}
              </div>
              <div className={styles.additionalSelect}>
                {showSelector && (
                  <div className={`row mb-3`}>
                    <div className={`col-sm-1`}>
                      <span
                        className={`${styles.circle} ${styles.plus}`}
                      ></span>
                    </div>
                    <div className={`col-sm-11`}>
                      <AsyncSelect
                        className={
                          classNameAsync ? `${styles.selectorBorder}` : null
                        }
                        loadOptions={(input, callback) => {
                          debouncedFetchLocalities(
                            { input, listLocalities },
                            callback
                          )
                        }}
                        value={null}
                        onChange={option => multipleLocalityHandler(option)}
                        getOptionLabel={option =>
                          option.l +
                          " " +
                          (option.pt === "H" ? "HOUSE" : "UNIT")
                        }
                        getOptionValue={option => option}
                        placeholder="Enter Suburb Name"
                      />
                    </div>
                  </div>
                )}
                <PaymentFormSelector
                  showSelector={showSelector}
                  click={setShowSelectorHandler}
                  listLocalities={listLocalities}
                  removeMessage={removeSuburbMess}
                />
                <div className="row mb-3">
                  <TotalPriceDisplay
                    coupon={coupon}
                    total={overAllPrice}
                    localities={listLocalities}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Field
                  name="isStripeComplete"
                  component={CardElementWithFormik}
                />
              </div>

              <div className="mb-3">
                <Coupon
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  coupon={coupon}
                  setCoupon={setCoupon}
                />
              </div>

              <LegalTerms />

              <button
                type="submit"
                disabled={
                  isSubmitting ||
                  listLocalities.length < 1 ||
                  listLocalities.length > 15 ||
                  validationArray.includes(false)
                }
                className={styles.submitButton}
              >
                Submit
              </button>

              {error && <div>Server Error</div>}
            </Form>
          )}
        </Formik>
      </LoadingOverlay>
    )
  }
)

const LegalTerms = () => (
  <Fragment>
    <label>
      <Field name="hasAgreedToTermsConditions" type="checkbox" /> I agree to the{" "}
      <a
        href="/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.legalTermsLink}
      >
        terms and conditions
      </a>{" "}
      and{" "}
      <a
        href="/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.legalTermsLink}
      >
        privacy policy
      </a>
    </label>
    <ErrorMessage name="hasAgreedToTermsConditions" component="div" />
  </Fragment>
)

// ============================================================================
// HELPERS
// ============================================================================

const fetchLocalities = (data, callback) => {
  const { input, listLocalities } = data
  // prettier-ignore
  fetch(`${process.env.GATSBY_API_URL}localities/search/?query=${encodeURIComponent(input)}`)
      .then(res => res.json()) //json parse
      .then(json => {
        let items = json.data.items
        const diff = _.differenceWith(items, listLocalities, (test, test1) => {
          return test.id === test1.id && test.pt === test1.propertyType
        })
        callback(diff)
      })
}

const debouncedFetchLocalities = _.debounce(fetchLocalities, 250)

function confirmEmail(ref, msg) {
  return Yup.mixed().test({
    name: "confirmEmail",
    exclusive: false,
    message: msg || "Please confirm your email",
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref)
    },
  })
}
Yup.addMethod(Yup.string, "confirmEmail", confirmEmail)

const PaymentFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must be at most 50 characters")
    .required("First name is required"),
  lastName: Yup.string()
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must be at most 50 characters")
    .required("Last name is required"),
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is required"),
  confirmEmail: Yup.string()
    .confirmEmail(
      Yup.ref("email"),
      "Email addresses don't match. Please check again"
    )
    .required("Please confirm your email"),
  coupon: Yup.string(),
  isStripeComplete: Yup.bool()
    .oneOf([true], "Credit card information is required")
    .nullable(),
  hasAgreedToTermsConditions: Yup.bool()
    .oneOf(
      [true],
      "Accepting terms and conditions and privacy policy is required"
    )
    .nullable(),
})

export default PaymentFormContainer
