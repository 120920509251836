import React, { useState, Fragment } from "react"
import { CardElement } from "react-stripe-elements"

import styles from "./CardElementWithFormik.module.css"

const CardElementWithFormik = ({
  field: { name },
  form: { touched, errors, setFieldError, setFieldTouched, setFieldValue },
}) => {
  const [stripeError, setStripeError] = useState(null)

  return (
    <Fragment>
      <CardElement
        className={styles.StripeElement}
        style={{
          base: { fontSize: "20px", color: "#495057" },
          invalid: { color: "#9e2146" },
        }}
        onBlur={() => setFieldTouched(name, true)}
        onChange={({ complete, error }) => {
          if (error) {
            setStripeError(error.message)
            // Record errors in Formik state
            setFieldError(name, error.message)
          } else {
            setStripeError(null)
          }
          setFieldValue(name, complete)
        }}
      />
      {/* Let Stripe errors take precedence over Yup errors */}
      <div>{stripeError || (touched[name] && errors[name])}</div>
    </Fragment>
  )
}

export default CardElementWithFormik
