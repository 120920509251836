import React from "react"
import styles from "../PaymentForm.module.css"
import { format as formatDate } from "date-fns"
import formatNumber from "format-number"

const ListLocality = ({ locality, removeClick, price, listLocalities }) => {
  let percentDisplay = ""
  if (listLocalities.length === 2) {
    percentDisplay = "(10% Off)"
  } else if (listLocalities.length > 2 && listLocalities.length < 6) {
    percentDisplay = "(20% Off)"
  } else if (listLocalities.length > 5 && listLocalities.length <= 9) {
    percentDisplay = "(30% Off)"
  } else if (listLocalities.length >= 10 && listLocalities.length <= 15) {
    percentDisplay = "(35% Off)"
  }

  if (!locality.hasData) {
    return (
      <>
        <div onClick={removeClick}>
          <div className={`col-sm-8 ${styles.wholeAlertDiv}`}>
            <div className={` ${styles.mouseOver}`}>
              <li className={styles.localList}>
                <span className={`${styles.minus} ${styles.circleNeg}`}></span>
                {locality.location}
              </li>
            </div>
          </div>
          <div className={`col-sm-12 ${styles.wholeAlertDiv}`}>
            {locality.propertyType === "H" ? "Houses" : "Units"} -{" "}
            {formatDate(new Date(locality.month), "MMMM yyyy")}
          </div>
          <p className={styles.wholeInsufficientAlert}>
            "Sorry, we don't have reliable data for this suburb.
            <span
              className={`${styles.alertInsufficientData} ${styles.mouseOver}`}
            >
              &nbsp;Please choose another suburb here.
            </span>
            "
          </p>
        </div>
      </>
    )
  } else if (locality.hasData) {
    return (
      <>
        <div
          className={`col-sm-8 ${styles.mouseOver} ${styles.eachListLocality}`}
        >
          <li className={styles.localList} onClick={removeClick}>
            <span className={`${styles.minus} ${styles.circleNeg}`}></span>
            {locality.location}
          </li>
        </div>
        <div className={`col-sm-4 text-right`}>
          <p className={`${styles.pricePar}`}>
            <span className={`${styles.discountDisplay}`}>
              {percentDisplay}
            </span>
            &nbsp;
            {formatNumber({ prefix: "$" })(price)}
          </p>
        </div>
        <div className="col-sm-12">
          {locality.propertyType === "H" ? "Houses" : "Units"} -{" "}
          {formatDate(new Date(locality.month), "MMMM yyyy")}
        </div>
      </>
    )
  }

  return <div></div>
}

export default ListLocality
