import React, { useState } from "react"
import _ from "lodash"
import AsyncSelect from "react-select/async"
import styles from "./SuburbSearchForm.module.css"

const SuburbSearchForm = ({
  onSuburbChange,
  jumpToStep,
  isOpen,
  modalSecondStep,
}) => {
  const [hasSufficientData, setHasSufficientData] = useState(true)
  const [firstFormFieldValue, setFirstFromFieldValue] = useState(null)

  const checkDataHandler = option => {
    if (isOpen && option === null) {
      setFirstFromFieldValue(null)
    } else if (isOpen && option !== null) {
      setHasSufficientData(!!option.ok)
      setFirstFromFieldValue(option)
    }
  }

  return (
    <form
      onSubmit={ev => {
        modalSecondStep(true)
        ev.preventDefault()
        jumpToStep(1)
      }}
    >
      <div className={styles.suburbSearchForm}>
        <section className={styles.title}>
          <h1>Suburb Growth Prediction Report</h1>
        </section>

        <section className={styles.content}>
          <h2 className={styles.subHeader}>
            See the capital growth potential for...
          </h2>

          <div className={styles.inputBox}>
            <AsyncSelect
              isClearable
              styles={customStyles}
              loadOptions={debouncedFetchLocalities}
              value={firstFormFieldValue}
              onChange={option => {
                onSuburbChange(option)
                checkDataHandler(option)
              }}
              getOptionLabel={option =>
                option.l + " " + (option.pt === "H" ? "HOUSE" : "UNIT")
              }
              getOptionValue={option => option}
              placeholder="Enter Suburb Name"
            />
            <button
              type="submit"
              className={styles.button}
              disabled={!hasSufficientData || !firstFormFieldValue}
            >
              Buy Now
            </button>
          </div>
          {!hasSufficientData ? (
            <div className={`${styles.dataMessage}`}>
              <p className="text-center">
                There is insufficient data available on this market
                <br />
                for us to be confident about our forecast.
                <br />
                Please choose another market.
              </p>
            </div>
          ) : null}
        </section>
      </div>
    </form>
  )
}

// ============================================================================
// HELPERS
// ============================================================================

const fetchLocalities = (input, callback) => {
  // prettier-ignore
  fetch(`${process.env.GATSBY_API_URL}localities/search/?query=${encodeURIComponent(input)}`)
    .then(res => res.json())
    .then(json => 
      callback(json.data.items)
    )
}

const debouncedFetchLocalities = _.debounce(fetchLocalities, 250)
const customStyles = {
  control: () => ({
    minHeight: "100%",
    padding: "0 8px",
    alignItems: "center",
    background: "#fff",
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    transition: "all 100ms",
    boxSizing: "border-box",
  }),
  menu: () => ({
    color: "#000",
    background: "#fff",
    marginTop: "4px",
    position: "absolute",
    width: "100%",
    zIndex: "1",
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
}

export default SuburbSearchForm
