import React, { useState, Fragment } from "react"
import Modal from "react-modal"
import StepZilla from "react-stepzilla"

import SuburbSearchForm from "./SuburbSearchForm"
import PaymentForm from "./PaymentForm"
import styles from "./PurchaseReportModal.module.css"
import "./PurchaseReportModal.css"

const PurchaseReportModal = ({ opener }) => {
  const [isOpen, setOpen] = useState(false)
  const [suburbOption, setSuburbOption] = useState(null)
  const [secondStep, setSecondStep] = useState(false)

  const modalSecondStep = toggle => {
    setSecondStep(toggle) //boolean, check if the modal is at the second step to change modal size per step
  }

  const steps = [
    {
      name: "Step 1",
      component: (
        <SuburbSearchForm
          isOpen={isOpen}
          onSuburbChange={option => setSuburbOption(option)}
          suburbOption={suburbOption}
          modalSecondStep={modalSecondStep}
        />
      ),
    },
    {
      name: "Step 2",
      component: (
        <PaymentForm
          suburbOption={suburbOption}
          onSubmit={() => setOpen(false)}
        />
      ),
    },
  ]

  return (
    <Fragment>
      <span onClick={() => setOpen(true)}>{opener}</span>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setOpen(false)
          modalSecondStep(false)
        }}
        contentLabel="Purchase Report Dialog"
        className={secondStep ? styles.modalStepTwo : styles.modal}
      >
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => setOpen(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div className="step-progress">
          <StepZilla
            steps={steps}
            showNavigation={false}
            showSteps
            stepsNavigation={false}
            preventEnterSubmission
          />
        </div>
      </Modal>
    </Fragment>
  )
}

export default PurchaseReportModal
