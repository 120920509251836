import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import queryString from "query-string"
import classNames from "classnames"
import { format as formatDate } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapMarkedAlt,
  faGlobe,
  faChartLine,
  faCity,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
import Modal from "react-modal"
import "bootstrap/dist/css/bootstrap.min.css"

import PurchaseReportModal from "../PurchaseReportModal/PurchaseReportModal"
import Button from "../Button/Button"
import ReportDisclaimer from "../ReportDisclaimer/ReportDisclaimer"
import logoHeader1 from "../../images/suburb-growth-logo-01.svg"
import logoHeader2 from "../../images/suburb-growth-logo-02.svg"
import styles from "./ReportLayout.module.css"

// SSR rendering: https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
if (typeof window !== "undefined") {
  // eslint-disable-next-line no-unused-expressions
  import("bootstrap")
}

Modal.setAppElement("#___gatsby")

const ReportLayout = ({ urlSearch, children }) => {
  const { id: reportId } = queryString.parse(urlSearch)

  const { isLoading, report, hasError } = useReport(reportId)

  if (isLoading) {
    return <Loader />
  } else if (!report || hasError) {
    return <ErrorMessage />
  }

  const propertyType = report.prop_type_code === "H" ? "Houses" : "Units"
  const date = formatDate(new Date(report.mnt), "MMMM yyyy")

  return (
    <div className={styles.container}>
      <div className={classNames("d-none d-lg-block", styles.sidebar)}>
        <div className={styles.sidebarLogoContainer}>
          <Link to="/">
            <img
              className={styles.sidebarLogo}
              src={logoHeader2}
              alt="Suburb Growth logo"
            />
          </Link>
        </div>
        <NavItems urlSearch={urlSearch} />
      </div>

      <main className={styles.main}>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <span className="navbar-text">
            Property Prediction Report - {report.locality_full} - {propertyType}{" "}
            - {date}
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav mr-auto d-lg-none">
              <NavItems urlSearch={urlSearch} />
            </div>
          </div>
        </nav>

        <div>{children({ report })}</div>
        <Disclaimer />
      </main>
    </div>
  )
}

// ============================================================================
// SUB-COMPONENTS
// ============================================================================

const Loader = () => (
  <div className={styles.fullScreenContainer}>
    <div className={styles.fullScreenCenter}>
      <div
        className={classNames("spinner-border", styles.loaderSpinner)}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
      <p>Loading report...</p>
    </div>
  </div>
)

const ErrorMessage = () => {
  const email = {
    subject: "Suburb Growth - Error when retrieving report",
    body: "Payment Email: <Your email address>",
  }

  return (
    <div className={styles.fullScreenContainer}>
      <div className={styles.fullScreenCenter}>
        <div className="container text-left">
          <Link to="/">
            <img
              src={logoHeader1}
              className={styles.reportErrorLogo}
              alt="Suburb Growth logo"
            />
          </Link>

          <h1 className="h2">An error occured</h1>

          <p>There was an error retrieving your report.</p>
          <p>
            Please contact our support and provide your purchasing email
            address.
          </p>
          <p>
            Contact support: {/* prettier-ignore */}
            <a href={`mailto:info@suburbgrowth.com.au?${queryString.stringify(email)}`}>
              info@suburbgrowth.com.au
            </a>
          </p>

          <Link to="/">
            Return home <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
    </div>
  )
}

const NavItems = ({ urlSearch }) => {
  return (
    <Fragment>
      <ul className="nav flex-column">
        <li className="nav-item">
          <NavItem
            to="growth-prediction"
            icon={<FontAwesomeIcon fixedWidth icon={faGlobe} />}
            search={urlSearch}
          >
            Growth Prediction
          </NavItem>
        </li>
        <li className={styles.bonusDataList}>
          <p className={styles.bonusData}>Bonus Data:</p>
        </li>
        <li className="nav-item">
          <NavItem
            to="price-history"
            icon={<FontAwesomeIcon fixedWidth icon={faChartLine} />}
            search={urlSearch}
          >
            Price History
          </NavItem>
        </li>
        <li className="nav-item">
          <NavItem
            to="market-profile"
            icon={<FontAwesomeIcon fixedWidth icon={faCity} />}
            search={urlSearch}
          >
            Property Market Profile
          </NavItem>
        </li>
        <li className="nav-item">
          <NavItem
            to="map"
            icon={<FontAwesomeIcon fixedWidth icon={faMapMarkedAlt} />}
            search={urlSearch}
          >
            Map
          </NavItem>
        </li>
      </ul>

      <div className="text-center">
        <PurchaseReportModal
          opener={
            <Button theme="dark" className={styles.purchaseButton}>
              Buy A New Suburb
            </Button>
          }
        />
      </div>
    </Fragment>
  )
}

const NavItem = ({ to, icon, search, children }) => {
  const toWithoutSearch = "/report/" + to + "/"

  return (
    <Link
      getProps={({ location }) => ({
        className: classNames("nav-link", styles.navLink, {
          [styles.navLinkActive]:
            location.href && location.href.includes(toWithoutSearch),
        }),
      })}
      to={toWithoutSearch + search}
    >
      <span className={styles.navLinkIcon}>{icon}</span> {children}
    </Link>
  )
}

const Disclaimer = () => (
  <div className={styles.disclaimer}>
    <div className="container">
      <h4 className={styles.disclaimerHeading}>Report Disclaimer:</h4>
      <div className={styles.disclaimerText}>
        <ReportDisclaimer />
      </div>
    </div>
  </div>
)

// ============================================================================
// HELPERS
// ============================================================================

const reportCache = {}
const useReport = reportId => {
  // Use the cache if available
  const [report, setReport] = useState(reportCache[reportId] || null)
  const [isLoading, setLoading] = useState(reportCache[reportId] ? false : true)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (reportCache[reportId]) {
      setReport(reportCache[reportId])
      return
    }

    setLoading(true)
    fetch(`${process.env.GATSBY_API_URL}reports/${reportId}`)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          const report = json.data.report
          reportCache[reportId] = report
          setReport(report)
        } else {
          setHasError(true)
        }
        setLoading(false)
      })
  }, [reportId])

  return { isLoading, report, hasError }
}

export default ReportLayout
