import React from "react"
import styles from "../PaymentForm.module.css"

const PaymentFormSelector = ({
  listLocalities,
  removeMessage,
  click,
  showSelector,
}) => {
  let percentOffAmount
  if (listLocalities.length === 1 && listLocalities.length < 2) {
    percentOffAmount = "10% OFF total purchase"
  } else if (listLocalities.length > 1 && listLocalities.length <= 4) {
    percentOffAmount = "20% OFF total purchase"
  } else if (listLocalities.length >= 5 && listLocalities.length <= 8) {
    percentOffAmount = "30% OFF total purchase"
  } else if (listLocalities.length >= 9 && listLocalities.length <= 15) {
    percentOffAmount = "35% OFF total purchase"
  } else {
    percentOffAmount = ""
  }
  let moreThanLimit = ""
  let buttonToList = (
    <p
      onClick={click}
      className={`${styles.additionalSelect} ${styles.mouseOver}`}
    >
      {showSelector ? null : (
        <span className={`${styles.circle} ${styles.plus}`}></span>
      )}
      <span className={`${styles.addAnotherMessage} ${styles.green}`}>
        {listLocalities.length < 1
          ? `Please enter suburb above`
          : `Add suburb get a`}
      </span>
      <span className={`${styles.percentOffAmount} ${styles.green}`}>
        &nbsp;{`${percentOffAmount}`}
      </span>
    </p>
  )

  if (listLocalities.length === 15) {
    moreThanLimit = <p>You've reached the maximum amount of orders</p>
    buttonToList = null
  }

  //put cards on in review then add bitbucket repo

  return (
    <>
      <p className={`text-center`}>{removeMessage}</p>
      <div className={`row mb-12`}>
        <div className={`col-sm-12 text-left`}>{buttonToList}</div>
      </div>
      <div className={`row mb-12`}>
        <div className={`col-sm-6 text-left`}></div>
        <div className={`col-sm-6 text-right`}></div>
        <div className={`col-sm-12`}>
          <p className={`text-center ${styles.totalPriceDisplay}`}>
            {moreThanLimit}
          </p>
        </div>
      </div>
    </>
  )
}

export default PaymentFormSelector
