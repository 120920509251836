import React from "react"
import styles from "./PaymentModalMessage.module.css"

export const PaymentModalMessage = ({ children }) => {
  return (
    <div className={styles.purchaseCompleteMessage}>
      <h4 className={`${styles.lastStep}`}>Suburb Growth Report Purchase</h4>
      <p>Thank you for your purchase.</p>
      <p>
        An email containing your purchase receipt and links to access your
        report(s) has been dispatched to the email address you provided.
      </p>
      <p style={{ marginBottom: "2rem" }}>
        If you do not receive this email within a timely manner, please be sure
        you check it hasn’t made its way into your junk box. If you still
        haven’t received it, please contact our support team{" "}
        <a rel="noopener noreferrer" href="/contact-us" target="_blank">
          here.
        </a>
      </p>

      {children}

      <p style={{ marginTop: "2rem", marginBottom: "0.25rem" }}>
        To Your Success,
      </p>
      <p style={{ marginBottom: "0.25rem" }}>The SRP Team</p>
      <p style={{ fontSize: 12 }}>
        Home of{" "}
        <a
          rel="noopener noreferrer"
          href="https://suburbgrowth.com.au/"
          target="_blank"
          style={{ color: "inherit" }}
        >
          SuburbGrowth.com.au
        </a>
        ,{" "}
        <a
          rel="noopener noreferrer"
          href="https://www.locationscore.com.au/"
          target="_blank"
          style={{ color: "inherit" }}
        >
          LocationScore.com.au
        </a>
        ,{" "}
        <a
          rel="noopener noreferrer"
          href="https://dsrdata.com.au/"
          target="_blank"
          style={{ color: "inherit" }}
        >
          DSRdata.com.au
        </a>{" "}
        and{" "}
        <a
          rel="noopener noreferrer"
          href="https://sellorhold.com.au/"
          target="_blank"
          style={{ color: "inherit" }}
        >
          SellorHold.com.au
        </a>
      </p>
    </div>
  )
}
