import React, { useState, Fragment } from "react"
import { Field, ErrorMessage } from "formik"
import formatNumber from "format-number"
import classNames from "classnames"

import styles from "./Coupon.module.css"

const Coupon = ({
  values,
  errors,
  setFieldValue,
  setFieldError,
  coupon,
  setCoupon,
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const [isLocked, setLocked] = useState(false)

  if (!isExpanded) {
    return (
      <p onClick={() => setExpanded(true)}>
        Have a special coupon code?{" "}
        <span className={styles.showCouponField}>Apply Here</span>
      </p>
    )
  }

  return (
    <Fragment>
      <div className={styles.couponSection}>
        <Field
          name="coupon"
          className={classNames(styles.couponInput, {
            [styles.couponInputLocked]: isLocked,
          })}
          readOnly={isLocked}
        />
        {isLocked ? (
          <button
            type="button"
            className={styles.couponButtonLocked}
            onClick={() => {
              setLocked(false)
              setFieldValue("coupon", "")
              setCoupon(null)
            }}
          >
            Change Coupon
          </button>
        ) : (
          <button
            type="button"
            className={styles.couponButton}
            onClick={async () => {
              if (!values.coupon) {
                setFieldError("coupon", "Invalid coupon")
                return setCoupon(null)
              }

              const coupon = encodeURIComponent(values.coupon.toUpperCase())
              const res = await fetch(
                `${process.env.GATSBY_API_URL}coupons/${coupon}`
              )
              const json = await res.json()

              if (json.success) {
                setCoupon(json.data.coupon)
                setLocked(true)
              } else {
                setFieldError("coupon", "Invalid coupon")
                setCoupon(null)
              }
            }}
          >
            Apply Coupon
          </button>
        )}
      </div>
      <div className="text-center">
        <ErrorMessage name="coupon" component="div" />
      </div>

      {coupon && !errors.coupon && (
        <p className={styles.couponMessage}>
          Valid coupon,{" "}
          {coupon.amount_off
            ? formatNumber({ prefix: "$", padRight: 2 })(
                coupon.amount_off / 100
              )
            : formatNumber({ suffix: "%" })(coupon.percent_off)}{" "}
          discount applied
        </p>
      )}
    </Fragment>
  )
}

export default Coupon
