import React from "react"
import _ from "lodash"
import classNames from "classnames"
import formatNumber from "format-number"
import { format as formatDate } from "date-fns"
import * as d3 from "d3"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

import ReportLayout from "../../components/ReportLayout/ReportLayout"
import styles from "./growth-prediction.module.css"

const GrowthPredictionPage = ({ location }) => (
  <ReportLayout urlSearch={location.search}>
    {({ report }) => {
      const sorted = [...report.trend, ...report.forecast]
        .map(x => ({ ...x, mntUnixTime: new Date(x.mnt).getTime() }))
        .sort((a, b) => a.mntUnixTime - b.mntUnixTime)

      /*
        We render 4 area charts: a historical values chart, a year1Prediction
        values chart, a year2Prediction values chart, and a year3Prediction
        values chart. Each chart requires its own data source, so we map additional
        properties onto each datapoint. The layering of these charts is also
        important, see JSX comment.

        e.g: a historical datapoint sourced from report.trend is transformed
        original: {
          mnt: ISO_Date,
          tv: number
        }
        transformed: { 
          historical: tv,
          mnt: ISO_Date,
          mntUnixTime: number,
          tv: tv,
          year1Prediction: tv,
          year2Prediction: tv,
          year3Prediction: tv
        }
      */

      const data = sorted
        .map(mapHisorical)
        .map(mapYear1Predictions)
        .map(mapYear2Predictions)
        .map(mapYear3Predictions)

      // Source:
      // https://github.com/recharts/recharts/issues/1137#issuecomment-366381321
      const domain = d3
        .scaleTime()
        .domain([
          d3.timeDay.floor(_.first(data).mntUnixTime),
          d3.timeDay.ceil(_.last(data).mntUnixTime),
        ])
      const ticks = domain.ticks(d3.timeYear.every(1))

      return (
        <div className="container">
          <h2 className={styles.chartHeading}>Suburb Growth</h2>
          <div className={styles.chart}>
            <ResponsiveContainer>
              <AreaChart data={data}>
                <Legend verticalAlign="bottom" height={36} iconType="square" />
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="mntUnixTime"
                  domain={["dataMin", "dataMax"]}
                  scale="time"
                  type="number"
                  ticks={ticks}
                  tickFormatter={tick => new Date(tick).getFullYear()}
                  tickMargin={15}
                />
                <YAxis
                  tickFormatter={d3.format("$~s")}
                  tickMargin={10}
                  width={
                    // Need extra space for large numbers
                    data.some(x => x.tv >= 1000000) ? 70 : 60
                  }
                />
                <Tooltip content={<CustomTooltip />} />

                {/* The layering of these charts is important. Those that come 
                    after will overwrite those that came before */}
                <Area
                  name="Forecast Year 3"
                  type="monotone"
                  dataKey="year3Prediction"
                  fillOpacity={0.8}
                  // gold-lightest
                  fill="#FFE2B1"
                  stroke="#FFE2B1"
                />
                <Area
                  name="Forecast Year 2"
                  type="monotone"
                  dataKey="year2Prediction"
                  fillOpacity={0.8}
                  // gold-lighter
                  fill="#FFD082"
                  stroke="#FFD082"
                />
                <Area
                  name="Forecast Year 1"
                  type="monotone"
                  dataKey="year1Prediction"
                  fillOpacity={0.8}
                  // gold
                  fill="#FFC365"
                  stroke="#FFC365"
                />
                <Area
                  name="Historical"
                  type="monotone"
                  dataKey="historical"
                  fillOpacity={1}
                  // blue
                  fill="#5A9BFC"
                  stroke="#5A9BFC"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>

          <table
            className={classNames(
              "table table-responsive-lg",
              styles.detailsTable
            )}
          >
            <thead>
              <tr className={styles.tablePredictionHeadingRow}>
                <td></td>
                <td></td>
                <td colSpan={3} className="text-center">
                  Predictions
                </td>
              </tr>
              <tr>
                <td className="text-nowrap">
                  {report.locality_full} -{" "}
                  {report.prop_type_code === "H" ? "Houses" : "Units"}
                </td>
                <td className="text-right text-nowrap">Current Median</td>
                <td className="text-right">Year 1</td>
                <td className="text-right">Year 2</td>
                <td className="text-right">Year 3</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-nowrap">Predicted Typical Value</td>
                <td className="text-right">{formatCurrency(report.tv)}</td>
                {report.forecast.map((x, i) => (
                  <td key={i} className="text-right">
                    {formatCurrency(x.tv)}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="text-nowrap">Yearly Growth Forecast</td>
                <td></td>
                {report.forecast.map((x, i) => (
                  <td key={i} className="text-right">
                    {formatPercentage(x.growth_12mnts)}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="text-nowrap">Accumulative Growth Forecast</td>
                <td></td>
                {report.forecast.map((x, i) => (
                  <td key={i} className="text-right">
                    {formatPercentage(x.growth)}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="text-nowrap">Yearly Growth Gain</td>
                <td></td>
                {report.forecast.map((x, i) => (
                  <td key={i} className="text-right">
                    {formatCurrency(x.growth_12mnts$)}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="text-nowrap">Accumulative Growth Gain</td>
                <td></td>
                {report.forecast.map((x, i) => (
                  <td key={i} className="text-right">
                    {formatCurrency(x.growth$)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      )
    }}
  </ReportLayout>
)

// ============================================================================
// SUB-COMPONENTS
// ============================================================================

const CustomTooltip = ({ payload, label, active }) => {
  if (!active) return null

  const isHistoricalActive = !!payload[3]
  const values = payload[0].payload

  return (
    <div className={styles.tooltipContainer}>
      <h5 className={styles.tooltipHeading}>
        {isHistoricalActive ? "Historical" : "Forecast"}
      </h5>
      <div className={styles.tooltipDate}>
        {formatDate(new Date(label), "MM/yyy")}
      </div>

      {isHistoricalActive ? (
        <div className={styles.tooltipDollars}>{formatCurrency(values.tv)}</div>
      ) : (
        <table>
          <tbody>
            <tr>
              <td>Typical Value:</td>
              <td className="text-right">{formatCurrency(values.tv)}</td>
            </tr>
            <tr>
              <td>Growth:</td>
              <td className="text-right">{formatCurrency(values.growth$)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right">{formatPercentage(values.growth)}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}

// ============================================================================
// HELPERS
// ============================================================================

const mapHisorical = datapoint =>
  datapoint.years_of_growth
    ? datapoint
    : { ...datapoint, historical: datapoint.tv }
const mapYear1Predictions = datapoint =>
  // Read as, if datapoint exists after year of growth 1
  datapoint.years_of_growth > 1
    ? datapoint
    : { ...datapoint, year1Prediction: datapoint.tv }
const mapYear2Predictions = datapoint =>
  datapoint.years_of_growth > 2
    ? datapoint
    : { ...datapoint, year2Prediction: datapoint.tv }
const mapYear3Predictions = datapoint =>
  datapoint.years_of_growth > 3
    ? datapoint
    : { ...datapoint, year3Prediction: datapoint.tv }

const formatCurrency = formatNumber({ prefix: "$" })
const formatPercentage = formatNumber({ suffix: "%", padRight: 2 })

export default GrowthPredictionPage
