import React from "react"
import formatNumber from "format-number"
import styles from "../PaymentForm.module.css"

export const TotalPriceDisplay = props => {
  const { coupon, total, localities } = props
  const noDiscountPrice = 19.95
  let savings = noDiscountPrice * localities.length - total
  let savingsDisplay = formatNumber({ prefix: "$" })(savings.toFixed(2))
  let discountMessage
  if (coupon) {
    if (coupon.amount_off) {
      discountMessage = (
        <>
          {formatNumber({ prefix: "$" })(coupon.amount_off / 100) + ".00"}{" "}
          Discount
        </>
      )
    } else {
      discountMessage = (
        <>{formatNumber({ suffix: "%" })(coupon.percent_off)} Discount</>
      )
    }
  }
  return (
    <>
      <div className={`col-sm-7`}></div>
      <div className={`col-sm-5 text-right`}>
        <p className={`text-right ${styles.totalPriceDisplay}`}>
          Total: {formatNumber({ prefix: "$" })(total.toFixed(2))}
        </p>
      </div>
      <div className={`col-sm-7`}></div>
      <div className={`col-sm-5 text-right`}>
        <p className={`${styles.totalPriceDisplay}`}>{discountMessage}</p>
        <p className={`${styles.totalPriceDisplay} ${styles.green}`}>
          {savings === 0 ? null : `[Savings: ${savingsDisplay}]`}
        </p>
      </div>
    </>
  )
}
