module.exports.roundOff = (num, dec = 2) => {
  if (dec <= 0) return 0
  return Math.round(num * 10 ** dec) / 10 ** dec
}

module.exports.computedPrice = (localities, currentPrice) => {
  if (localities.length < 2) {
    return this.roundOff(currentPrice / 100, 2)
  } else if (localities.length === 2) {
    let eachPrice = (currentPrice / 100) * 0.9
    return this.roundOff(eachPrice, 2) + 0.01
  } else if (localities.length > 2 && localities.length <= 5) {
    let eachPrice = (currentPrice / 100) * 0.8
    return this.roundOff(eachPrice, 2)
  } else if (localities.length >= 6 && localities.length <= 9) {
    let eachPrice = (currentPrice / 100) * 0.7
    return this.roundOff(eachPrice, 2) + 0.01
  } else if (localities.length >= 10 && localities.length <= 15) {
    let eachPrice = (currentPrice / 100) * 0.65
    return this.roundOff(eachPrice, 2)
  }
}
